import { Link } from "react-router-dom"
import { Container } from "../Container/Container"
import { Spacer } from "../../../classes/UI/Spacer"
import { MailComponent } from "../MailComponent/MailComponent"
import { PhoneComponent } from "../PhoneComponent/PhoneComponent"
import { Form } from "../Form/Form"

import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps"
import { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import 'swiper/css';
import styles from './HomeComponents.module.css'

export const FS = () => {
    const [isSlider, setIsSlider] = useState(window.innerWidth < 640);

    const handleResize = () => {
        setIsSlider(window.innerWidth < 640);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            <div className={styles.fs}>
                <div className={styles.fs_cont}>
                    <h1 className={styles.fs_h}>Станки с ЧПУ<br />Инструмент и оснастка</h1>
                    <div>
                        {isSlider
                            ? (<>
                                <Swiper>
                                    <SwiperSlide className={styles.fs_adv}>
                                        <img src="/ms_f_svg.svg" alt="" />
                                        <p>Индивидуальный подход</p>
                                        <p>Мы подберем для вас станок и инструмент исходя из ваших потребностей.</p>
                                    </SwiperSlide>
                                    <SwiperSlide className={styles.fs_adv}>
                                        <img src="/ms_t_svg.svg" alt="" />
                                        <p>Гарантия качества</p>
                                        <p>Наша компания поставляет только высококачественную продукцию.</p>
                                    </SwiperSlide>
                                    <SwiperSlide className={styles.fs_adv}>
                                        <img src="/ms_th_svg.svg" alt="" />
                                        <p>От оплаты и до вас</p>
                                        <p>Безопасность и надежность сделки - наш приоритет.</p>
                                    </SwiperSlide>
                                </Swiper>
                            </>)
                            : (<>
                                <div className={styles.fs_advs}>
                                    <div className={styles.fs_adv}>
                                        <img src="/ms_f_svg.svg" alt="" />
                                        <p>Индивидуальный подход</p>
                                        <p>Мы подберем для вас станок и инструмент исходя из ваших потребностей.</p>
                                    </div>
                                    <div className={styles.fs_adv}>
                                        <img src="/ms_t_svg.svg" alt="" />
                                        <p>Гарантия качества</p>
                                        <p>Наша компания поставляет только высококачественную продукцию.</p>
                                    </div>
                                    <div className={styles.fs_adv}>
                                        <img src="/ms_th_svg.svg" alt="" />
                                        <p>От оплаты и до вас</p>
                                        <p>Безопасность и надежность сделки - наш приоритет.</p>
                                    </div>
                                </div>
                            </>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export const AC = () => {
    return (
        <>
            <div className={styles.ac}>
                <div className={styles.ac_bg_gears}><img src="gears.svg" alt="" /></div>
                <div className={styles.ac_bg_man}><img src="manufacture.svg" alt="" /></div>
                <Container clname={styles.ac_c}>
                    <div className={styles.ac_img}><img src="ac_stanok.jpeg" alt="" /></div>
                    <div className={styles.ac_content}>
                        <div>
                            <p className={styles.ac_ab}>о компании</p>
                            <h3>ООО “КОНТУР”</h3>
                            <p className={styles.ac_text}>Мы являемся надежным поставщиком станков с ЧПУ, инструментов и расходных материалов.</p>
                            <MailComponent />
                            <Spacer h={12} />
                            <PhoneComponent />
                            <Spacer h={16} />
                            <p className={styles.ac_addr}>г. Санкт-Петербург, ул. Бурцева, д.23 лит. А, офис 9</p>
                        </div>
                        <Link className={styles.ac_link} to='/about'>
                            <span>Подробнее</span>
                            <img src="arrow-right.svg" alt="" />
                        </Link>
                    </div>
                </Container>
            </div>
        </>
    )
}

export const FH = () => {
    const plholders = ["Телефон", "Имя", "Почта"];

    return (
        <div className={styles.form}>
            <div className={styles.form_container}>
                <div className={styles.form_left}>
                    <h3>Оставьте ваши контакты</h3>
                    <p>И наши специалисты проконсультируют вас по всем интересующим вопросам</p>
                    <Form clname={styles.form_form} plholders={plholders} URI="/feedback" text="Форма ОС с главной страницы" />
                    <Link to='/contacts'><span>Подробнее</span><img src="/arrow-right.svg" alt="" /></Link>
                </div>
                <YMaps>
                    <div id="formMap" className={styles.form_right}>
                        <Map width={100 + '%'} height={100 + '%'} defaultState={{ center: [59.836011, 30.213914], zoom: 12 }}>
                            <Placemark geometry={[59.836011, 30.213914]} />
                        </Map>
                    </div>
                </YMaps>
            </div>
        </div>
    )
}