import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../axios";
import { Products } from "../../pages/Products/Products";

export const ProductsContainer = ({ URI }) => {
    const params = useParams()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        apiUrl
            .get(URI, { params })
            .then((res) => {
                setData(res.data)
                setLoading(false)
            })
            .catch((err) => { console.log(err) })
    }, [])

    return (
        <>
            <Products loading={loading} data={data} />
        </>
    )
}