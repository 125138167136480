import { Outlet } from "react-router-dom";
import { Container } from "../../components/UI/Container/Container"
import { Feedback } from "../../components/Parts/Feedback/Feedback";
import { CategoriesHelmet } from "../../components/Helmets/CategoriesHelmet";

import styles from "./Categories.module.css"

export const Categories = () => {
    return (
        <>
            <CategoriesHelmet/>
            <Container>
                <div className={styles.container}>
                    <Outlet />
                </div>
            </Container>
            <Feedback />
        </>
    )
}
