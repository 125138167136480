import { Container } from "../../components/UI/Container/Container"

export const Payment = () => {
    return (
        <Container>
            <div style={{ "margin": "10vh 0" }}>
                <h1 style={{ "font-size": "36px", "margin": "36px 0", "border-bottom": "1px solid #518FB3", "padding": "0 100px 10px 0", "width": "fit-content" }}>Оплата</h1>
                <div>
                    Уважаемые партнеры, заказать у нас станок Вы можете, очень просто:<br />
                    Для этого достаточно отправить нам запрос с характеристиками, которые Вам необходимы, через
                    <ul style={{ "list-style": "circle", "margin": "12px 0" }}>
                        <li>форму обратной связи на сайте</li>
                        <li>позвонив по номеру телефона: <a style={{ "color": "#518FB3" }} href="tel:88129244260">+7(812)924-42-60</a></li>
                        <li>написав письмо на email: <a style={{ "color": "#518FB3" }} href="mailto:info@conturspb.ru">info@conturspb.ru</a></li>
                    </ul>
                    Наличие и сроки изготовления станка уточняйте по одной из форм обратной связи.<br />
                    Условия и форма оплаты индивидуальны для каждого заказчика.
                </div>
            </div>
        </Container>
    )
}