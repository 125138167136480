export const texts = {
    main: {
        title: "Контур СПБ | Токарные, фрезерные станки с ЧПУ"
    },
    catalogue: {
        title: "Каталог товаров | Контур СПб",
    },
    product: {
        title: " | Контур СПб"
    }
}