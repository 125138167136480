import { Routes, Route, Navigate } from 'react-router-dom';

import { PageTitle } from "./components/Parts/PageTitle/PageTitle";
import { Home } from './pages/Home/Home';
import { Header } from './components/Parts/Header/Header';
import { Categories } from './pages/Categories/Categories';
import { FullProduct } from './pages/FullProduct/FullProduct';
import { DataContainer } from './components/Containers/DataContainer';
import { ProductsContainer } from './components/Containers/ProductsContainer'
import { Footer } from './components/Parts/Footer/Footer';
import { Liked } from './pages/Liked/Liked';
import { Cart } from './pages/Cart/Cart'
import { Payment } from './pages/Payment/Payment';

import './App.css';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route 
          path="/" 
          element={<Home />} />
        <Route 
          path="/catalogue" 
          element={<Categories/>}>
          
          <Route index element={<DataContainer URI="/catalog/categories" />} />
          <Route path=":category_id">
            <Route index element={<DataContainer URI="/catalog/subcategories" />} />
            <Route path=":subcategory_id" element={<ProductsContainer isProducts URI="/catalog/products" />} />
          </Route>
        </Route>
        <Route path="/products/:product_id" element={<FullProduct />} />
        <Route path="/liked" element={<Liked />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/payments" element={<Payment />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;